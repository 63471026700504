<template>
<div>
  <div id="main" class="site-main">

<div id="main-content" class="main-content">

<mainslider/>
<div id="page-title" class="page-title-block page-title-style-1 has-background-image" style="background-image: url(/assets/backgrounds/title/06.jpg);"><div class="container"><div class="page-title-icon"><div class="sc-icon sc-icon-size-medium angle-90deg sc-icon-shape-circle" style="background-color: #ffffff;border: 2px solid #ffffff;"><span class="sc-icon-half-1" style=""><span class="back-angle">&#xe7a9;</span></span><span class="sc-icon-half-2" style=""><span class="back-angle">&#xe7a9;</span></span></div></div><div class="page-title-title"><h1 style="">  Медиа о нас</h1></div></div></div>




<div class="block-content">
	<div class="container">
		<div class="panel row">

			<div class="panel-center col-xs-12">
				<article id="post-11707" class="post-11707 page type-page status-publish hentry">


					<div class="entry-content post-content">
												<div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div ></div> <div class="gallery-preloader-wrapper"> <div class="row"> <div class="sc-gallery-grid col-lg-12 col-md-12 col-sm-12 hover-zooming-blur" data-hover="zooming-blur"> <ul class="gallery-set col-lg-12 col-md-12 col-sm-12 clearfix fullwidth-block"> <li class="gallery-item double-item post-11707 page type-page status-publish hentry"> <div class="wrap "> <div class="overlay-wrap"> <div class="image-wrap ">


                          <img src="/assets/backgrounds/media-o-nas/tchaikovsky-house-0712015-scalia-gallery-justified-double.jpg" width="660" height="600" alt=""> </div> <div class="overlay "> <div class="overlay-content"> <div class="overlay-content-center"> <div class="overlay-content-inner"> <a href="/assets/backgrounds/media-o-nas/tchaikovsky-house-0712015.jpg" class="icon photo fancy-gallery" rel="gallery-63a0be0072fc6" > <span class="slide-info"> <span class="slide-info-title"> Государственный мемориальный музыкальный музей-заповедник П.И.Чайковского </span> <span class="slide-info-summary"> 07.12.2015</span> </span> </a> <a href="http://tchaikovsky-house-museum.ru/pages2015/20151206_open_lesson.shtml" target="_blank" class="icon link"></a> <div class="title"> Государственный мемориальный музыкальный музей-заповедник П.И.Чайковского </div> <div class="subtitle"> 07.12.2015</div> </div> </div> </div> </div> </div> </div> </li> <li class="gallery-item double-item post-11707 page type-page status-publish hentry">

                          <div class="wrap "> <div class="overlay-wrap"> <div class="image-wrap ">

                            <img src="/assets/backgrounds/media-o-nas/edu-2310015-scalia-gallery-justified-double.jpg" width="660" height="600" alt=""> </div> <div class="overlay "> <div class="overlay-content"> <div class="overlay-content-center"> <div class="overlay-content-inner">
                                  <a href="/assets/backgrounds/media-o-nas/edu-2310015.jpg" class="icon photo fancy-gallery" rel="gallery-63a0be0072fc6" > <span class="slide-info"> <span class="slide-info-title"> Федеральный портал «Российское образование» </span> <span class="slide-info-summary"> 23.10.2015 </span> </span> </a>
                                  <a href="http://www.edu.ru/news/culture/muzei-podmoskovya-provedut-dlya-shkolnikov-uroki/" target="_blank" class="icon link"></a>
                                  <div class="title"> Федеральный портал «Российское образование» </div>
                                  <div class="subtitle"> 23.10.2015 </div> </div> </div> </div> </div> </div> </div> </li>
                                   <li class="gallery-item double-item post-11707 page type-page status-publish hentry"> <div class="wrap "> <div class="overlay-wrap"> <div class="image-wrap "> <img src="/assets/backgrounds/media-o-nas/podmoskovie-2310015-scalia-gallery-justified-double.jpg" width="660" height="600" alt=""> </div> <div class="overlay "> <div class="overlay-content"> <div class="overlay-content-center"> <div class="overlay-content-inner">
                                     <a href="/assets/backgrounds/media-o-nas/podmoskovie-2310015.jpg" class="icon photo fancy-gallery" rel="gallery-63a0be0072fc6" > <span class="slide-info"> <span class="slide-info-title"> Интернет-портал «Подмосковье сегодня» </span> <span class="slide-info-summary"> 23.10.2015 </span> </span> </a>
                                     <a href="http://mosregtoday.ru/obrazovaniye/obrazovatelnyy-protsess-v-shkolakh-mo-raznoobrazyat-puteshestviyami-rozhnov/" target="_blank" class="icon link"></a> <div class="title"> Интернет-портал «Подмосковье сегодня» </div> <div class="subtitle"> 23.10.2015 </div> </div> </div> </div> </div> </div> </div> </li> <li class="gallery-item post-11707 page type-page status-publish hentry"> <div class="wrap "> <div class="overlay-wrap"> <div class="image-wrap ">
                                       <img src="/assets/backgrounds/media-o-nas/mosreg-2310015-scalia-gallery-justified.jpg" width="440" height="400" alt=""> </div> <div class="overlay "> <div class="overlay-content"> <div class="overlay-content-center"> <div class="overlay-content-inner">
                                         <a href="/assets/backgrounds/media-o-nas/mosreg-2310015.jpg" class="icon photo fancy-gallery" rel="gallery-63a0be0072fc6" > <span class="slide-info"> <span class="slide-info-title"> Министерство культуры Московской области </span> <span class="slide-info-summary"> 23.10.2015 </span> </span> </a>
                                         <a href="http://mk.mosreg.ru/multimedia/novosti/novosti/23-10-2015-12-08-13-v-podmoskove-startoval-pilotnyy-proekt-uroki-v-gor/" target="_blank" class="icon link"></a>
                                         <div class="title"> Министерство культуры Московской области </div>
                                         <div class="subtitle"> 23.10.2015 </div> </div> </div> </div> </div> </div> </div> </li>
                                         <li class="gallery-item double-item post-11707 page type-page status-publish hentry"> <div class="wrap ">
                                           <div class="overlay-wrap"> <div class="image-wrap ">
                                              <img src="/assets/backgrounds/media-o-nas/melichovo-1710015-scalia-gallery-justified-double.jpg" width="660" height="600" alt=""> </div>
                                               <div class="overlay "> <div class="overlay-content"> <div class="overlay-content-center"> <div class="overlay-content-inner">
                                                  <a href="/assets/backgrounds/media-o-nas/melichovo-1710015.jpg" class="icon photo fancy-gallery" rel="gallery-63a0be0072fc6" > <span class="slide-info"> <span class="slide-info-title"> ГОСУДАРСТВЕННЫЙ ЛИТЕРАТУРНО-МЕМОРИАЛЬНЫЙ МУЗЕЙ-ЗАПОВЕДНИК А.П. ЧЕХОВА «МЕЛИХОВО» </span>
                                                    <span class="slide-info-summary"> 17.10.2015 </span> </span> </a> <a href="http://chekhovmuseum.com/visitors/news/1983/v-melikhove-proshel-urok-literatury/" target="_blank" class="icon link"></a>
                                                    <div class="title"> ГОСУДАРСТВЕННЫЙ ЛИТЕРАТУРНО-МЕМОРИАЛЬНЫЙ МУЗЕЙ-ЗАПОВЕДНИК А.П. ЧЕХОВА «МЕЛИХОВО» </div>
                                                    <div class="subtitle"> 17.10.2015 </div> </div> </div> </div> </div> </div> </div> </li>
                                                    <li class="gallery-item double-item post-11707 page type-page status-publish hentry"> <div class="wrap ">
                                                      <div class="overlay-wrap"> <div class="image-wrap ">
                                                        <img src="/assets/backgrounds/media-o-nas/mosreg-1208015-scalia-gallery-justified-double.jpg" width="660" height="600" alt=""> </div>
                                                        <div class="overlay "> <div class="overlay-content"> <div class="overlay-content-center"> <div class="overlay-content-inner">
                                                           <a href="/assets/backgrounds/media-o-nas/mosreg-1208015.jpg" class="icon photo fancy-gallery" rel="gallery-63a0be0072fc6" > <span class="slide-info"> <span class="slide-info-title"> Министерство культуры Московской области </span> <span class="slide-info-summary"> 12.08.2015 </span> </span> </a>
                                                           <a href="http://mk.mosreg.ru/multimedia/novosti/novosti/08-12-2015-11-25-39-v-muzee-zapovednike-a-p-chekhova-melikhovo-proydet/" target="_blank" class="icon link"></a>
                                                           <div class="title"> Министерство культуры Московской области </div>
                                                           <div class="subtitle"> 12.08.2015 </div> </div> </div> </div> </div> </div> </div> </li>
                                                           <li class="gallery-item double-item post-11707 page type-page status-publish hentry"> <div class="wrap "> <div class="overlay-wrap"> <div class="image-wrap ">
                                                             <img src="/assets/backgrounds/media-o-nas/russiatourism-20082015-scalia-gallery-justified-double.jpg" width="660" height="600" alt=""> </div> <div class="overlay ">
                                                               <div class="overlay-content"> <div class="overlay-content-center"> <div class="overlay-content-inner">
                                                                 <a href="/assets/backgrounds/media-o-nas/russiatourism-20082015.jpg" class="icon photo fancy-gallery" rel="gallery-63a0be0072fc6" > <span class="slide-info"> <span class="slide-info-title"> Министерство Культуры Российской Федерации Федеральное агентство по туризму </span> <span class="slide-info-summary"> 20.08.2015 </span> </span> </a> <a href="http://russiatourism.ru/contents/turism_v_rossii/regions/tsentralnyy-fo/moskovskaya-oblast/v-moskve-v-preddverii-1-sentyabrya-predstavlen-detskiy-kulturno-poznavatelnyy-turistskiy-proekt-uroki-v-gorodakh-rossii-podmoskove" target="_blank" class="icon link"></a>
                                                                 <div class="title"> Министерство Культуры Российской Федерации Федеральное агентство по туризму </div>
                                                                 <div class="subtitle"> 20.08.2015 </div> </div> </div> </div> </div> </div> </div> </li>
                                                                 <li class="gallery-item double-item post-11707 page type-page status-publish hentry"> <div class="wrap "> <div class="overlay-wrap"> <div class="image-wrap ">
                                                                   <img src="/assets/backgrounds/media-o-nas/mosreg-0107015-scalia-gallery-justified-double.jpg" width="660" height="600" alt=""> </div> <div class="overlay "> <div class="overlay-content"> <div class="overlay-content-center"> <div class="overlay-content-inner">
                                                      <a href="/assets/backgrounds/media-o-nas/mosreg-0107015.jpg" class="icon photo fancy-gallery" rel="gallery-63a0be0072fc6" > <span class="slide-info"> <span class="slide-info-title"> Министерство культуры Московской области </span> <span class="slide-info-summary"> 01.07.2015 </span> </span> </a>
                                                      <a href="http://mk.mosreg.ru/multimedia/novosti/novosti/01-07-2015-16-27-03-podmoskove-stanet-pilotnoy-ploshchadkoy-po-razviti/" target="_blank" class="icon link"></a> <div class="title"> Министерство культуры Московской области </div> <div class="subtitle"> 01.07.2015</div> </div> </div> </div> </div> </div> </div> </li> </ul> </div> </div> </div><div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 2px;"></div></div>
                                                    </div>
                                                  </div>
                                                    </div>
                                                    </p>
					</div><!-- .entry-content -->







				</article><!-- #post-## -->

			</div>


		</div>


	</div>
</div>



</div><!-- #main-content -->
</div>
</div>
</template>

<script>
import mainslider from "@/components/mainslider.vue"
  import {rollup} from "@/lib/utils.js"
export default {
name : 'News_mediaonas',
components : {mainslider},
mounted() {
rollup()

}

}


</script>
